<template>
  <div>
    <b-row>
      <b-col md="4">
        <b-form-group
          label-for="name"
          label="Razão Social"
        >
          <b-form-input
            id="name"
            v-model="filter.legal_tax_name"
            placeholder="Razão Social"
          />
        </b-form-group>
      </b-col>

      <b-col md="4">
        <b-form-group
          label-for="tax-id"
          label="CNPJ"
        >
          <b-form-input
            id="tax-id"
            v-model="filter.establishment"
            placeholder="CNPJ"
          />
        </b-form-group>
      </b-col>

      <b-col md="4">
        <b-form-group
          label-for="value"
          label="Valor Total"
        >
          <b-form-input
            id="tax-id"
            v-model="filter.contract_value"
            v-money="money"
            placeholder="Valor Total"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-table
      :items="items"
      :per-page="10"
      responsive
      striped
      hover
      :filter="filter"
      :fields="fields"
      class="text-center"
    >
      <template #cell(taxId)="data">
        {{ data.item.merchant.tax_id | tax_id }}
      </template>

      <template #cell(name)="data">
        {{ data.item.merchant.name }}
      </template>

      <template #cell(contract_due_date)="data">
        {{ data.item.contract_due_date | date }}
      </template>

      <template #cell(signature_date)="data">
        {{ data.item.signature_date | date }}
      </template>

      <template #cell(contract_value)="data">
        {{ data.item.contract_value | value }}
      </template>

      <template #cell(unaccounted_value)="data">
        {{ data.item.unaccounted_value | value }}
      </template>

      <template #cell(unpaid_value)="data">
        {{ data.item.unpaid_value | value }}
      </template>

      <template #cell(liquidated_value)="data">
        {{ data.item.liquidated_value | value }}
      </template>

      <template #cell(actions)="data">
        <b-dropdown
          variant="link"
          toggle-class="text-decoration-none"
          no-caret
        >
          <template v-slot:button-content>
            <feather-icon
              icon="MoreHorizontalIcon"
              size="16"
              class="text-body align-middle mr-25"
            />
          </template>
          <b-dropdown-item @click="$router.push(`/plataforma-credito/averbacao/${data.item.id}`)">
            <feather-icon
              icon="EyeIcon"
              class="mr-50"
            />
            <span>Detalhes</span>
          </b-dropdown-item>
        </b-dropdown>
      </template></b-table>

    <b-pagination
      v-model="page"
      :total-rows="total"
      :per-page="10"
      align="center"
      size="sm"
      class="mt-2"
    />
  </div>
</template>

<script>
import {
    BTable, BPagination, BDropdown,
    BDropdownItem, BFormInput, BCol, BRow, BFormGroup,
} from 'bootstrap-vue';

export default {
    components: {
        BTable,
        BPagination,
        BDropdown,
        BDropdownItem,
        BFormInput,
        BCol,
        BRow,
        BFormGroup,
    },

    props: {
        items: {
            required: true,
            type: Array,
        },

        total: {
            required: true,
            type: Number,
        },

        filter: {
            required: true,
            type: Object,
        },
    },

    data() {
        return {
            page: 1,
            money: {
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                precision: 2,
                masked: false,
            },
            fields: [
                'id',
                { label: 'CNPJ', key: 'taxId' },
                { key: 'name', label: 'Razão Social' },
                { key: 'signature_date', label: 'Data do Contrato' },
                { key: 'contract_due_date', label: 'Data de Vencimento' },
                { key: 'contract_value', label: 'Valor Total' },
                { key: 'unaccounted_value', label: 'Em Aberto' },
                { key: 'unpaid_value', label: 'A Expirar' },
                { key: 'liquidated_value', label: 'Expirado' },
                { key: 'actions', label: 'Ações' },
            ],
        };
    },

    watch: {
        page(value) {
            this.$emit('changePage', value);
        },
    },
};
</script>
