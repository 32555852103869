<template>
  <b-container
    fluid
  >
    <b-card>
      <h2 class="text-center">
        Contratos
      </h2>
      <b-row class=" mt-3">
        <b-col
          v-for="item in summaryData"
          :key="item.title"
        >
          <SummaryItem
            :info="item.info"
            :title="item.title"
          />
        </b-col>
      </b-row>

    </b-card>
    <b-card>
      <Table
        :filter="filter"
        :total="totalEndorsements"
        :items="items"
        @changePage="changePage"
      />
    </b-card>
  </b-container>
</template>

<script>
import {
    BContainer, BCard, BRow, BCol,
} from 'bootstrap-vue';
import Table from './components/Table.vue';

import SummaryItem from '@/views/components/SummaryItem.vue';

import EndorsementService from '@/services/osiris/EndorsementService';

import formatValue from '@/filters/value';

export default {
    components: {
        Table,
        BContainer,
        BCard,
        SummaryItem,
        BRow,
        BCol,
    },

    props: {
        summary: {
            type: Object,
            default: () => {},
        },
    },

    data() {
        return {
            items: [],
            page: 1,
            limit: 10,
            summaryItem: { ...this.summary },
            totalEndorsements: 1,
            filter: {
                establishment: '',
            },
        };
    },

    computed: {
        summaryData() {
            if (this.summaryItem.total === undefined) return [];
            return [
                {
                    info: String(this.summaryItem.total),
                    title: 'Nº Contratos Aprovados',
                },
                {
                    info: String(this.summaryItem.due_contracts),
                    title: 'Ativos',
                },
                {
                    info: String(this.summaryItem.expired_contracts),
                    title: 'Finalizados',
                },
                {
                    info: formatValue(this.summaryItem.due_amount),
                    title: 'A Expirar',
                },
                {
                    info: formatValue(this.summaryItem.expired_amount),
                    title: 'Expirado',
                },
            ];
        },
    },

    watch: {
        'filter.legal_tax_name': function () {
            this.getOverall();
        },

        'filter.establishment': function () {
            this.getOverall();
        },

        'filter.contract_value': function () {
            this.getOverall();
        },
    },

    async mounted() {
        this.isLoading(true);
        await Promise.all([
            this.getOverall(),
            this.getSummary(),
        ]);
        this.isLoading(false);
    },

    methods: {
        async getOverall() {
            const { data } = await EndorsementService.overall({
                limit: 10,
                page: this.page || 1,
                name: this.filter.legal_tax_name,
                establishment: this.filter.establishment.replace(/[^a-zA-Z0-9]/g, ''),
                contract_value: +this.filter.contract_value.replace(/[R$ .]/g, '').replace(',', '.') || undefined,
            });

            this.totalEndorsements = data.meta.total;
            this.items = data.data;
        },

        async getSummary() {
            if (this.summaryItem.total === undefined) {
                const { data } = await EndorsementService.getSummary();
                this.summaryItem = data;
            }
        },

        changePage(page) {
            this.page = !page ? 1 : page;
            this.getOverall();
        },
    },
};
</script>
